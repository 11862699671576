.ContactFormContainer {
  /* background-color: rgba(222, 184, 135, 0.473); */
  width: 50%;
  height: 510px;
}
.contactDetailsContainer {
  background-image: url("../Images//ContactBg.png");
  margin: 40px 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  width: 100%;
  height: 510px;
  display: flex;
  justify-content: end;
}
input {
  width: 100%;
  height: 40px;
  border-bottom: 2px solid rgb(180, 180, 180);
  transition: 0.2s ease-in;
  outline: none;
  padding: 0px 10px;
  border-radius: 05px;
  font-weight: 500;
}
input:focus {
  border-bottom: 1px solid #028ec9;
  transition: 0.2s ease-out;
  height: 40px;
  /* cursor: pointer; */
}

textarea {
  width: 100%;
  height: 40px;
  border-bottom: 2px solid rgb(180, 180, 180);
  transition: 0.2s ease-in;
  outline: none;
  font-weight: 600;
  border-radius: 5px;
}
textarea:focus {
  border-bottom: 1px solid #028ec9;
  transition: 0.2s ease-out;
  height: 40px;
  /* cursor: pointer; */
}
.submitbtn {
  height: auto;
  font-weight: 600 !important;
  background-color: #028ec9 !important;
  color: white !important;
}

label {
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
    1px 1px 0 white;
}

.form_container {
  /* background-color: rgba(0, 0, 0, 0.836); */
  z-index: 0;
  position: relative;
  padding: 20px;
  backdrop-filter: blur(10px);
  /* backdrop-filter: brightness(200%); */
  /* backdrop-filter: contrast(200%) saturate(150%); */
}
@media screen and (max-width: 1000px) {
  .form_container {
    background-color: rgba(0, 0, 0, 0.836);
    z-index: 0;
    position: relative;
    padding: 20px;
    backdrop-filter: blur(0px);
    border-radius: 20px;
    /* backdrop-filter: brightness(200%); */
    /* backdrop-filter: contrast(200%) saturate(150%); */
  }

  .contactDetailsContainer {
    background-image: url("../Images//ContactBg.png");
    margin: 40px 0px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 510px;
    display: flex;
    justify-content: end;
  }
  .ContactFormContainer {
    width: 70%;
    margin: auto auto;
  }
  label {
    /* text-shadow: -1px -1px 0 rgb(0, 0, 0), 1px -1px 0 rgb(0, 0, 0), -1px 1px 0 rgb(0, 0, 0),
      1px 1px 0 white; */
    text-shadow: none;
    color: white;
  }
}

@media screen and (max-width: 800px) {
  .ContactFormContainer {
    width: 80%;
    margin: auto auto;
  }
}

@media screen and (max-width: 800px) {
  .ContactFormContainer {
    width: 90%;
    margin: auto auto;
  }
}
