/* Total width and height of the scrollbar */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Background of the scrollbar */
::-webkit-scrollbar-track {
  background: #076e9a !important;
  border: 1px solid #000000 !important;
  border-radius: 10px !important;
}

/* Draggable part of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #ffffff !important;
  border: 1px solid #000000 !important;
  border-radius: 10px;
}

/* Hover effect for the draggable part */
::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3 !important;
}

.CommonHeadingStyle {
  position: relative;
  text-transform: capitalize;
  font-size: 25px;
}
.CommonHeadingStyle::before {
  content: "";
  display: inline;
  position: absolute;
  width: 40%;
  height: 3px;
  bottom: -10px;
  background-color: #076e9a;
}

.CommonHeadingStyleContainer {
  margin: 20px auto;
  position: relative;
  /* z-index: -1; */
}
.HTHI-Container {
  width: 80%;
  max-width: 400px;
  margin-top: 20px;
  /* height: 85%; */
  /* margin: auto auto; */
}
.paragraph {
  font-size: clamp(1rem, 0.6833rem + 1.0133vw, 1.18rem);
}

.uploadbutton {
  width: 200px;
  /* height: auto; */
  border: 3px solid #076e9a;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.336);
  transition: all 0.3s;
  cursor: pointer;
  padding: 10px 0px;
  /* background: white; */
  /* color: black; */
  font-size: 20px !important;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  animation: blinkbanner 1.4s linear infinite;
}

.uploadbutton:hover {
  background: #076e9a;
  color: white;
  font-size: 1.3em;
}

@keyframes blinkbanner {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
.bannerContainer {
  display: flex;
  background-image: url("../../Images/BannerBg.png");
  color: white;
  border-radius: 20px;
  padding: 40px 70px;
  align-items: center;
}
.bannerContainer div h5 i {
  line-height: 38px;
  font-size: 22px;
  /* margin: 0px 20px; */
}

.WhyWorkWithUs {
  background-color: #076e9a;
  padding: 40px;
  color: white;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 254px;
  perspective: 1000px;
  font-family: sans-serif;
}

.title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
}
.slider-containernyc {
  height: 220px;
  background-color: rgba(0, 0, 0, 0.87);
}
.industrialial {
  background-image: url("../../Images/Industrial.png");
  width: 100%;
  height: 200px;
}
.commercial {
  background-image: url("../../Images/Commerical.png");
  width: 100%;
  height: 200px;
}
.residential {
  background-image: url("../../Images/Residential.png");
  width: 100%;
  height: 200px;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateX(180deg);
}

.flip-card-front,
.flip-card-back {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid rgb(255, 255, 255);
  /* border-radius: 1rem; */
}

.flip-card-front {
  /* background-color: ; */
  color: rgb(255, 255, 255);
}

.flip-card-back {
  /* background: linear-gradient(
    120deg,
    rgb(255, 174, 145) 30%,
    coral 88%,
    bisque 40%,
    rgb(255, 185, 160) 78%
  ); */
  color: white;
  transform: rotateX(180deg);
}

.card2 {
  width: 250px;
  height: 300px;
  perspective: 1000px;
}

.card-inner2 {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.999s;
}

.card2:hover .card-inner2 {
  transform: rotateY(180deg);
}

.card-front2,
.card-back2 {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  padding: 10px;
}

.card-back2 p {
  font-size: 16px;
  /* text-align: center; */
}

.card-front2 {
  background-color: #ffffff;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #2c706773;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(0deg);
  /* box-shadow: 03px 3px 7px 3px rgba(150, 150, 150, 0.1),
    -3px -3px 7px 3px rgba(150, 150, 150, 0.1); */
}

.card-back2 {
  background-color: #076e9a;
  color: #fff;
  display: flex;
  align-items: center;
  border: 3px solid #000000;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(180deg);
}

.card3 {
  width: 230px;
  height: 240px;
  perspective: 1000px;
}

.card-inner3 {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.999s;
}

.card3:hover .card-inner3 {
  transform: rotateY(180deg);
}

.card-front3,
.card-back3 {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-front3 {
  background-color: #ffffff;
  color: #076e9a;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid #2c706773; */
  border-radius: 10px;
  justify-content: center;
  font-size: 20px;
  transform: rotateY(0deg);
  /* box-shadow: 03px 3px 7px 3px rgba(150, 150, 150, 0.1),
    -3px -3px 7px 3px rgba(150, 150, 150, 0.1); */
}

.card-back3 {
  background-color: white;
  color: #076e9a;
  display: flex;
  align-items: center;
  /* border: 1px solid #838383; */
  border-radius: 10px;
  justify-content: center;
  font-size: 18px;
  padding: 0px 12px;
  transform: rotateY(180deg);
}
.ourRangeContainer {
  color: #076e9a;
  border: 3px solid #076e9a;
  border-radius: 20px;
  padding: 20px;
}
.BlueRowsContainer {
  background-color: #028ec9;
  color: white;
  padding: 30px 80px;
}
.ourRangeContainer ul li {
  font-weight: 600;
}
.HeroSectionContainer {
  background-image: url("../../Images//TradeHeroBG.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px 20px;
}
.contactContainer {
  background-color: #076e9a;
  padding: 30px;
}

.dropdown-item:hover {
  color: whitesmoke !important;
  background-color: #076e9a !important;
  transition: 0.3s ease !important;
}
.dropdown-item:hover a {
  color: whitesmoke !important;
}

.commoncard {
  background-size: cover;
  position: relative;
  color: white;
  width: 280px;
  height: 220px;
  background-color: #076e9a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  border-radius: 1px;
  cursor: pointer;
}

.commoncard::before,
.commoncard::after {
  position: absolute;
  content: "";
  width: 20%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  background-color: #028dc900;
  transition: all 0.5s;
}

.commoncard::before {
  top: 0;
  right: 0;
  border-radius: 0 1px 0 100%;
}

.commoncard::after {
  bottom: 0;
  left: 0;
  border-radius: 0 100% 0 1px;
}

.commoncard:hover::before,
.commoncard:hover:after {
  width: 100%;
  height: 100%;
  border-radius: 1px;
  background-color: #028dc9a4;
  transition: all 0.5s;
}

.commoncard:hover:after {
  content: "Read More";
  font-size: 18px !important;
  text-decoration: underline;
}
.ReviewCardContainer {
  width: 95%;
  margin: auto auto;
  background: #014663;
  background: -webkit-linear-gradient(89deg, #014663 0%, #076e9a 100%);
  background: linear-gradient(89deg, #014663 0%, #076e9a 100%);
  color: white;
  border-radius: 20px;
  padding: 20px 30px;
}
.ReviewCardContainer p:nth-child(1) {
  min-height: 72px;
}
.slick-arrow {
  background-color: #076e9a !important;
  border-radius: 50%;
}
.Off_40_percentContainer {
  max-width: 400px;
  width: 100%;
  height: 360px;
  padding: 20px;
  background-image: url("../../Images/OffBG.png");
  color: white;
  background-size: cover;
  display: flex;
  justify-content: start;
  align-items: end;

  /* background-color: #076e9a; */
}

.TakeOffBlueContainer {
  background-color: #076e9a;
  color: white;
  padding: 40px 60px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cleckHereBtn {
  color: black;
  padding: 5px;
  background-color: white;
  transition: 0.5s ease-in-out;
  animation: blinking 2s infinite;
  border-radius: 10px;
  font-weight: 600;
}

@keyframes blinking {
  0% {
    color: black;
    background-color: white;
    transition: 0.5s ease-in-out;
  }
  50% {
    color: white;
    background-color: black;
    transition: 0.5s ease-in-out;
    padding: 10px;
  }
  100% {
    color: black;
    background-color: white;
    transition: 0.5s ease-in-out;
  }
}

.div1 {
  padding: 30px;
  background-image: url("../../Images/Div1.png");
  border-radius: 10px;
  min-height: 190px;
  background-size: cover;
  background-position: center center;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.div2 {
  padding: 30px;
  text-align: center;
  background-image: url("../../Images/div2.png");
  border-radius: 10px;
  min-height: 190px;
  background-size: cover;
  background-position: center center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.div3 {
  padding: 30px;
  text-align: center;
  background-image: url("../../Images/div3.png");
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
  margin-top: 15px;
  margin-bottom: 15px;
  min-height: 190px;
}

.MepDetailsContainer {
  background-image: url("../../Images/MEPBG.png");
  padding: 20px;
  color: white;
}
.widthdiv {
  width: 350px;
}
.popUpContainer {
  transition: 0.3s ease-in;
  transition-delay: 5s;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #000000ab;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popUp {
  background-color: white;
  padding: 40px 40px;
  width: 500px;
  height: auto;
  position: relative;
}
.btnclose {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
}
.PopUpContentContainer {
  width: 70%;
}
.PopUpContent {
  width: 100%;
  box-shadow: -8px -8px 8px 3px grey;
  z-index: 2;
  padding: 20px;
  position: relative;
  padding-bottom: 05px;
}
.iconContainer {
  position: absolute;
  bottom: 20px;

  right: 20px;
  z-index: 0;
}

@media screen and (max-width: 1100px) {
  .bannerContainer {
    padding: 30px 50px;
    align-items: center;
  }
}
@media screen and (max-width: 800px) {
  .TakeOffBlueContainer {
    background-color: #076e9a;
    color: white;
    padding: 20px 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .CommonHeadingStyle {
    font-size: 22px;
  }
  .bannerContainer {
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    align-items: center;
  }
  .bannerContainer div h5 i {
    line-height: 30px;
    font-size: 20px;
  }
  .BlueRowsContainer {
    background-color: #076e9a;
    color: white;
    padding: 15px 15px;
  }
}

@media screen and (max-width: 600px) {
  .TakeOffBlueContainer {
    background-color: #076e9a;
    color: white;
    padding: 15px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .bannerContainer {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    align-items: center;
  }
  .bannerContainer div h5 i {
    line-height: 25px;
    font-size: 18px;
  }
  .CommonHeadingStyle {
    font-size: 20px;
  }
  .statspic {
    width: 100px;
  }
  .reviewPersomName {
    font-size: 18px !important;
  }
  .reviewPersomDesignation {
    font-size: 16px !important;
  }
  .BlueRowsContainer {
    background-color: #076e9a;
    color: white;
    padding: 10px 10px;
  }
}

@media screen and (max-width: 400px) {
  .TakeOffBlueContainer {
    background-color: #076e9a;
    color: white;
    padding: 10px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .CommonHeadingStyle {
    font-size: 18px;
  }
  .reviewPersomName {
    font-size: 16px !important;
  }
  .reviewPersomDesignation {
    font-size: 14px !important;
  }
  .statspic {
    width: 60px;
  }
}
